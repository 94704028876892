<template>
  <div v-loading="fetching || $h.isFetching()">
    <el-table
      v-bind="$attrs"
      :data="renderData"
      @cell-click="viewRow"
      ref="table"
      @select="handleSelection"
      @selection-change="handleSelectionChange"
    >
      <slot></slot>
    </el-table>

    <div
      style="margin-top: 10px; display: flex; justify-content: space-between"
      v-if="!nopagination"
    >
      <div class="d-flex align-items-center">
        <el-select
          v-model="Pagination.SizeTotal"
          @change="handleSizeChange"
          placeholder=""
          class="entries-select"
        >
          <el-option
            v-for="item in pageSizeOptions"
            :key="item.value"
            :label="$t.translate(item.label)"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div class="mx-2">{{ $t.translate("LBL_ITEMS") }}</div>
      </div>

      <el-pagination
        v-model:current-page="Pagination.CurrentPage"
        background
        :pager-count="$h.isSmall() ? 1 : 5"
        :layout="$h.isSmall() ? 'prev, next' : 'prev, pager, next'"
        :total="Pagination.PageTotal"
        :page-size="Pagination.SizeTotal"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed } from "vue";
import { get, isValidResponse } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { extendedConfigs } from "@/utils/configs";
import { useMq } from "vue3-mq";

export default {
  props: {
    nopagination: {
      type: Boolean,
      default: false,
    },
    additionalArr: Array,
    viewData: Function,
    select: Function,
    selection: Function,
    loaded: Function,
    url: String,
    listField: String,
    query: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const mq = useMq();
    let table = ref("table");
    let multipleFilter = ref({});
    let { defaultSize, pageSizeOptions } = extendedConfigs;

    let Pagination = reactive({
      Items: [],
      PageTotal: 0,
      SizeTotal: defaultSize,
      CurrentPage: 1,
    });
    let Filter = reactive({ Filter: "" });
    let fetching = ref(true);

    const clearSelection = () => {
      table.value.clearSelection();
    };

    const handleSizeChange = async (value) => {
      Pagination.SizeTotal = value;
      handleCurrentChange(1);
    };

    const renderData = computed(() => {
      let arr = [...(props?.additionalArr || []), ...Pagination.Items];
      return arr;
    });

    const viewRow = (row, opt) => {
      if (opt?.className?.indexOf("action-col") > -1) return;
      if (props?.viewData) props.viewData(row);
    };

    const handleSelectionChange = (arr) => {
      if (props?.selection) props.selection(arr);
    };

    const handleSelection = (selection, row) => {
      if (props?.select) props.select(selection, row, table.value);
    };

    const handleCurrentChange = async (page, query) => {
      if (page) Pagination.CurrentPage = page;

      let searchObj = {
        page: Pagination.CurrentPage,
        size: Pagination.SizeTotal,
        ...(props.query || {}),
        ...(query || {}),
        ...Filter,
      };

      helpers.getPeriodMode(searchObj);

      if (searchObj.From) {
        searchObj.From = helpers.formatDateValue(searchObj.From);
      }
      if (searchObj.To) {
        searchObj.To = helpers.formatDateValue(searchObj.To);
      }

      fetching.value = true;
      let res = await get(props.url, searchObj);
      fetching.value = false;
      if (!isValidResponse(res)) return;

      Pagination.PageTotal = props?.listField
        ? res?.[props?.listField]?.length
        : res.totalData;
      Pagination.Items = props?.listField ? res?.[props?.listField] : res.data;

      setTimeout(() => {
        for (let row of Pagination.Items) {
          if (row.Assigned) {
            table.value.toggleRowSelection(row);
          }
        }
        if (props?.loaded) props.loaded();
      });
    };

    const currentSelection = (arr, field) => {
      multipleFilter.value[field] = _.map(arr, (r) => r.RowKey);

      _.each(multipleFilter.value, (r, f) => {
        if (!r.length) {
          delete multipleFilter.value[f];
        }
      });

      if (!Object.keys(multipleFilter.value).length) {
        Filter.Filter = "";
      } else {
        Filter.Filter = helpers.signToken(multipleFilter.value);
      }

      handleCurrentChange(1);
    };

    onMounted(async () => {
      handleCurrentChange(1); //get first page
    });

    return {
      Filter,
      clearSelection,
      multipleFilter,
      currentSelection,
      handleSizeChange,
      pageSizeOptions,
      Pagination,
      fetching,
      handleCurrentChange,
      viewRow,
      table,
      handleSelectionChange,
      handleSelection,
      renderData,
    };
  },
};
</script>
<style scoped>
.entries-select {
  width: 90px;
}
</style>
