<template>
  <div>
    <div class="container-fluid pt-8 pb-5">
      <el-button type="primary" class="mb-4" @click="addTeam">
        {{ $t.translate("BTN_ADD") }}
      </el-button>

      <div class="shadow bg-white p-around">
        <Table
          url="/business/team/list"
          :query="{ ...Filter }"
          ref="filttable"
          :viewData="handleEdit"
          height="64vh"
        >
          <el-table-column
            :label="$t.translate('TH_TEAM_NAME')"
            prop="Name"
            min-width="160"
            class-name="pl-1"
          >
          </el-table-column>

          <el-table-column
            :label="$t.translate('TH_HEAD')"
            min-width="160"
            class-name="pl-1"
          >
            <template #default="scope">
              {{ $h.getFullName(scope.row.TeamHead) }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t.translate('LBL_ACTION')"
            width="100"
            class-name="pl-1 action-col"
          >
            <template #default="scope">
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)"
                ><i class="fa fa-times"></i
              ></el-button>
            </template>
          </el-table-column>
        </Table>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "@/components/Table.vue";
import { reactive, onMounted, inject, ref } from "vue";
import { get, post, isValidResponse } from "@/utils/axios";
import { translation } from "@/utils/translation";
import { router } from "@/utils/router";
import { ElMessageBox } from "element-plus";
import { useRoute } from "vue-router";

export default {
  components: { Table },
  setup() {
    let filttable = ref("filttable");

    let Filter = reactive({});

    const addTeam = () => {
      router.changeRoute("/team/add");
    };

    const handleEdit = async (scope, row) => {
      if (row && row?.className?.indexOf("action-col") > -1) return;
      router.changeRoute(`/team/edit/${scope.BusinessTeamKey}`);
    };

    let filterAction = async () => {
      await filttable.value.handleCurrentChange(1, Filter);
    };

    const handleDelete = async (index, data) => {
      try {
        let confirmed = await ElMessageBox.confirm(
          translation.translate("MSG_WARNING_CONFIRM_DELETE"),
          translation.translate("TTL_WARNING"),
          {
            confirmButtonText: translation.translate("BTN_OKAY"),
            cancelButtonText: translation.translate("BTN_CANCEL"),
            type: "warning",
          }
        );
        let res = await post("/business/team/remove", {
          BusinessTeamKey: data.BusinessTeamKey,
        });
        if (!isValidResponse(res)) return;

        filterAction();
      } catch (err) {
        //err
      }
    };

    return { addTeam, filttable, handleDelete, handleEdit, Filter };
  },
};
</script>
<style></style>
